import React from "react";

const NotFound = () => {
  return (
    <div className="app-not-found">
      <h1>NOT FOUND</h1>
    </div>
  );
};

export default NotFound;
